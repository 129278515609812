import React from 'react';
import Header from './components/Header';
import AppRouter from './router/AppRouter';
import { AuthProvider } from './contexts/AuthContext'; // Import AuthProvider
import './App.css'; 
import TrackingComponent from './hooks/TrackingComponent';
import './global.css';
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie'; // Import js-cookie


function App() {

  return (
    <AuthProvider> {/* Wrap the entire app in AuthProvider */}
     <TrackingComponent /> 
      <div className="appContainer">
          <Header />
          <div className="subcontainer">
            <AppRouter />
            <CookieConsent
                location="bottom"
                buttonText="Anladım"
                cookieName="userConsent"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                expires={150}
                onAccept={() => Cookies.set('userConsent', 'true')}
                onDecline={() => Cookies.set('userConsent', 'false')}
            >
                Bu internet sitesi, deneyiminizi geliştirmek için çerezleri kullanmaktadır.
            </CookieConsent>
          </div>
      </div>
    </AuthProvider>
  );
}

export default App;


