// SongVersionList.jsx
import React, { useState } from 'react';
import styles from './SongVersionList.module.css'; // Ensure you have the proper CSS file and classes defined

const SongVersionList = ({ versionsInfo, currentVersion, onVersionChange, averageRating }) => {
  const [isListVisible, setIsListVisible] = useState(false);

  const toggleListVisibility = () => {
    setIsListVisible(!isListVisible);
  };

  const handleListItemClick = (version, uniqueSongId) => {
    onVersionChange(version, uniqueSongId);
    setIsListVisible(false); // Hide the list when a list item is clicked
  };

  if (!versionsInfo) {
    return null; // or some loading indicator
  }

  // Assuming `versionsInfo` is an object like: {"1": "songid1", "2": "songid2"}
  const versions = Object.keys(versionsInfo).map(key => ({
    version: key,
    uniqueSongId: versionsInfo[key]
  }));

  const renderStars = () => {
    const roundedRating = Math.round(averageRating);
    return [...Array(5)].map((_, i) => (
        <i
            key={i}
            className={`fas fa-star ${i < roundedRating ? 'filled' : ''}`}
            style={{ color: i < roundedRating ? 'orange' : 'grey', fontSize: '15px'}}
        />
    ));
};

  return (
    <div className={styles.versionBox}>
      <div className={styles.songVersion}>
        <button onClick={toggleListVisibility} className={styles.versionButton}>
          Versiyon {currentVersion}
        </button>
        {averageRating > 0 && (
                  <div className={styles.averageRating}>
                      {renderStars()}
                  </div>
        )}
      </div>
      {isListVisible && (
        <ul className={styles.versionList}>
          {versions.map((item) => (
            <li 
              key={item.version} 
              className={styles.versionItem} 
              onClick={() => handleListItemClick(item.version, item.uniqueSongId)}
            >
              Version {item.version}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SongVersionList;
