import React, { useState, useEffect } from 'react';
import styles from './ChordTransposer.module.css';
import { transposeChord } from '../utils/transposeChord';


const ChordTransposer = ({ songData, interval }) => {
    const [parsedData, setParsedData] = useState([]);

    //console.log('songData');
    //console.log(songData);

    useEffect(() => {
        try {
            const data = JSON.parse(songData);
            if (Array.isArray(data)) {
                setParsedData(data);
            } else {
                console.error('Parsed data is not an array:', data);
            }
        } catch (error) {
            console.error('Failed to parse song data:', error);
        }
    }, [songData]);

    const renderLine = (line, isChordLine) => {
        if (!isChordLine) {
            return <span className={styles.lyric}>{line}</span>;
        }
        return line.split(/(\s+)/).map((segment, index) => {
            if (segment.trim()) { 
                const transposedChord = transposeChord(segment, interval);
                return <span key={index} className={styles.chord}>{transposedChord}</span>;
            } else {
                // Directly return spaces as non-breaking spaces to preserve multiple spaces
                return <span key={index} className={styles.space} dangerouslySetInnerHTML={{ __html: segment.replace(/ /g, '&nbsp;') }} />;
            }
        });
    };

    return (
        <div>
            <pre className={styles.chordsContainer}>
                {parsedData.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderLine(item.line, item.line_type === 'Chords')}
                        <br />
                    </React.Fragment>
                ))}
            </pre>
        </div>
    );
};

export default ChordTransposer;
