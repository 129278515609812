import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authenticatedAxios, nonAuthenticatedAxios } from '../utils/axiosConfig';
import Cookies from 'js-cookie';
import styles from './AuthPage.module.css'; // You can rename LoginPage.module.css to AuthPage.module.css
import { AuthContext } from '../contexts/AuthContext';

function AuthPage() {
    const [isLogin, setIsLogin] = useState(true); // Toggle between login and signup
    const [email, setEmail] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const { login } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleAuth = async (event) => {
      event.preventDefault();
      const url = isLogin ? '/auth/login/' : '/auth/register/';
      const userData = isLogin ? { email, password } : { email, username, password };
      const csrfToken = Cookies.get('csrftoken');
  
      try {
          const response = await nonAuthenticatedAxios.post(url, userData, {
              headers: { 'X-CSRFToken': csrfToken }
          });
  
          if (response.status === 200 || response.status === 201) {
              await login(userData.email, userData.password);
              navigate('/');
          }
      } catch (error) {
          console.error('Authentication error:', error.response || error);
          const errors = error.response?.data;
          let errorMessages = [];
          if (errors && typeof errors === 'object') {
              Object.keys(errors).forEach(key => {
                  // Check if the error message under the key is an array and handle it accordingly
                  if (Array.isArray(errors[key])) {
                      errorMessages.push(...errors[key]);
                  } else if (typeof errors[key] === 'string') {  // Handle string error messages
                      errorMessages.push(errors[key]);
                  } else {
                      console.log('Unexpected error format:', errors[key]);
                  }
              });
              setError(errorMessages.join(" "));
          } else {
              setError("Bilinmeyen bir hata oluştu, lütfen farklı bilgilerle tekrar deneyiniz.");
          }
      }
  };
  
    

    const toggleForm = () => {
        setIsLogin(!isLogin);
        setError('');
    };

    return (
        <div className={styles.loginContainer}>
            <form onSubmit={handleAuth}>
                {isLogin ? (
                    <>
                        <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className={styles.input} autoComplete="email" />
                        <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} className={styles.input} autoComplete={isLogin ? "current-password" : "new-password"} />
                    </>
                ) : (
                    <>
                        <input type="text" placeholder="Kullanıcı Adı" value={username} onChange={(e) => setUsername(e.target.value)} className={styles.input} />
                        <input type="text" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} className={styles.input} />
                        <input type="password" placeholder="Şifre" value={password} onChange={(e) => setPassword(e.target.value)} className={styles.input} />
                    </>
                )}
                {error && <div className={styles.error}>{error}</div>}
                <button type="submit" className={styles.button}>{isLogin ? 'Giriş yap' : 'Kayıt Ol'}</button>
                <div className={styles.details}>
                    <a onClick={toggleForm} className={styles.link}>
                        {isLogin ? 'Kayıt Ol' : 'Zaten bir hesabınız var mı? Giriş yap'}
                    </a>
                </div>
            </form>
        </div>
    );
}

export default AuthPage;
