import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import useApi from '../hooks/useApi';
import SimilarArtists from '../components/SimilarArtists';
import ArtistsSongs from '../components/ArtistsSongs';
import { Helmet } from 'react-helmet';
import styles from './ArtistPage.module.css';

function ArtistPage() {
    const { artistName } = useParams();
    const params = useMemo(() => ({ unique_artist_id: artistName }), [artistName]);
    const { data: artistDetails, loading } = useApi('/api/akor/', params);
    const { data: similarArtist } = useApi('/api/song-category-similar-artists/', params);

    if (loading) return <div className={styles.loading}>Yükleniyor...</div>;
    if (!artistDetails.length) return <div className={styles.error}>Sanatçı detayları bulunamadı.</div>;

    const details = artistDetails[0];

    return (
        <div className={styles.artistPage}>
            <Helmet>
                <title>{details.artist_name} Şarkı Akorları - Türkiye'nin En Kapsamlı Müzik Portalı</title>
                <link rel="canonical" href={`https://www.melodikakor.com/akorlar/${artistName}`} />
                <meta name="description" content={`${details.artist_name} şarkılarının gitar akorları ve sözleri. ${details.artist_name} ile benzer sanatçıların şarkılarını keşfedin ve çalın.`} />
                <meta name="keywords" content={`${details.artist_name}, ${details.artist_name} akorlar, gitar akorları, müzik akorları, şarkı sözleri, akor çevrimleri, popüler sanatçılar`} />
                <meta property="og:title" content={`${details.artist_name} Şarkı Akorları - Türkiye'nin En Kapsamlı Müzik Portalı`} />
                <meta property="og:description" content={`${details.artist_name} şarkılarının gitar akorları ve sözleri. Benzer sanatçıların şarkı akorlarıyla müzik repertuarınızı genişletin.`} />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={`https://www.melodikakor.com/artists/${artistName}`} />
                <meta property="og:image" content="https://www.melodikakor.com/default-social-image.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${details.artist_name} Şarkı Akorları - Türkiye'nin En Kapsamlı Müzik Portalı`} />
                <meta name="twitter:description" content={`${details.artist_name} şarkılarının gitar akorları ve sözleri. Müzik tutkunuzu benzer sanatçılarla keşfedin.`} />
                <meta name="twitter:image" content="https://www.melodikakor.com/default-twitter-image.jpg" />
            </Helmet>
            <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "MusicGroup",
                "name": details.artist_name,
                "description": `${details.artist_name} şarkılarının gitar akorları ve sözleri. ${details.artist_name} ile benzer sanatçıların şarkılarını keşfedin ve çalın.`,
                "url": `https://www.melodikakor.com/artists/${artistName}`,
                "image": details.image || 'https://www.melodikakor.com/default-artist-image.jpg',
                "sameAs": details.socialMediaProfile || 'http://socialmedia.com/artistprofile',
                "album": details.albums ? details.albums.map(album => ({
                    "@type": "MusicAlbum",
                    "name": album.name,
                    "datePublished": album.releaseDate,
                    "url": `https://www.melodikakor.com/albums/${album.unique_album_id}`
                })) : [],
                "genre": details.genre || 'General genre of artist',
                "memberOf": {
                    "@type": "MusicGroup",
                    "name": "Similar Artists",
                    "member": similarArtist.map(artist => ({
                        "@type": "Person",
                        "name": artist.artist_name,
                        "url": `https://www.melodikakor.com/artists/${artist.unique_artist_id}`
                    }))
                }
            })}
            </script>

            <section className={styles.artistNameContainer}>
                <h1 className={styles.artistName}>{details.artist_name}</h1>
            </section>
            <section className={styles.songsSection}>
                <ArtistsSongs artistsSongs={artistDetails} />
            </section>
            <section className={styles.similarArtistsSection}>
                <h2 className={styles.subheader}>Önerilen Sanatçılar</h2>
                <SimilarArtists similarArtists={similarArtist} />
            </section>
        </div>
    );
}

export default ArtistPage;
