// src/utils/chordUtils.js

// Map chords directly to semitone positions on a 12-tone scale
const semitoneMap = {
    "C": 0, "C#": 1, "Db": 1, "D": 2, "D#": 3, "Eb": 3,
    "E": 4, "F": 5, "F#": 6, "Gb": 6, "G": 7, "G#": 8,
    "Ab": 8, "A": 9, "A#": 10, "Bb": 10, "B": 11
};

// Function to transpose chords
export const transposeChord = (chord, interval) => {
    return chord.replace(/[A-G]#?b?/g, (match) => {
        const index = semitoneMap[match];
        if (index === undefined) {
            console.error('Chord not found in map:', match);
            return match; // Return the unmatched part unchanged
        }
        const newIndex = (index + interval + 12) % 12; // Calculate new index with wrap-around
        for (let key in semitoneMap) {
            if (semitoneMap[key] === newIndex) {
                return key; // Return the new chord notation
            }
        }
    });
};
