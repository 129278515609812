import React from 'react';
import TopSongs from '../components/TopSongs';
import styles from './HomePage.module.css';
import { Helmet } from 'react-helmet';

const HomePage = () => {
    const howManyRows = 50;

    return (
        <div className={styles.pageStyle}>
            <Helmet>
                <title>Türkiye'nin En Kapsamlı Akor ve Müzik Portalı</title>
                <link rel="canonical" href="https://www.melodikakor.com/" />
                <meta name="description" content="Türkiye'nin en büyük akor arşivi, gerçek zamanlı akor transpoze, YouTube video bağlantıları ve gitar tuner özellikleriyle müzik pratiğinizi geliştirin." />
                <meta name="keywords" content="akor, tab, nota, akorlar, gitar akorları, müzik, transpoze, gitar tuner, YouTube müzik videoları, en popüler şarkılar, günün şarkıları" />
                <meta property="og:title" content="Türkiye'nin En Kapsamlı Akor ve Müzik Portalı" />
                <meta property="og:description" content="Her türden şarkının akorları, gitar için araçlar ve çok daha fazlası." />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.melodikakor.com" />
                <meta property="og:image" content="https://www.melodikakor.com/default-social-image.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Türkiye'nin En Kapsamlı Akor ve Müzik Portalı" />
                <meta name="twitter:description" content="Her türden şarkının akorları, gitar için araçlar ve çok daha fazlası." />
                <meta name="twitter:image" content="https://www.melodikakor.com/default-twitter-image.jpg" />
            

            <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "WebApplication",
                "name": "Türkiye'nin En Kapsamlı Akor ve Müzik Portalı",
                "url": "https://www.melodikakor.com",
                "applicationCategory": "Entertainment",
                "operatingSystem": "All platforms",
                "description": "Gitar akorları, gerçek zamanlı akor transpoze, YouTube video bağlantıları ve gitar tuner özellikleriyle müzik pratiğinizi geliştirin.",
                "offers": {
                    "@type": "Offer",
                    "url": "https://www.melodikakor.com"
                },
                "featureList": "Gerçek zamanlı akor transpoze, entegre YouTube video bağlantıları ve gitar ayarlama özellikleri sunar.",
                "keywords": "gitar akorları, müzik transpoze, YouTube müzik videoları, gitar tuner",
                "softwareHelp": {
                    "@type": "CreativeWork",
                    "name": "Türkiye'nin En Kapsamlı Akor ve Müzik Portalı Kullanım Kılavuzu",
                    "text": "Müzik transpozisyonunu nasıl kullanacağınızı öğrenin, herhangi bir şarkı için akorları bulun ve çevrimiçi tunerimizle gitarınızı akort edin."
                }
            })}
            </script>

            
            </Helmet>

            <section aria-labelledby="top-50-header" className={styles.pageStyle}>
                <h1 className={styles.visually_hidden} id="top-50-header">En İyi 50 Şarkı Akorları</h1>
                <TopSongs category_type='top-song' subcategory='all-time' title='En İyi 50' howManyRows={howManyRows}/>
            </section>
            <section aria-labelledby="today-playlist-header" className={styles.pageStyle2}>
                <h2 className={styles.visually_hidden} id="today-playlist-header">Bugünün Çalma Listesi Akorları</h2>
                <TopSongs category_type='top-song' subcategory='today' title='Bugünün Çalma Listesi' howManyRows={howManyRows}/>
            </section>
        </div>
    );
};

export default HomePage;
