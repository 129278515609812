import React, { useState, useEffect } from 'react';
import { authenticatedAxios } from '../utils/axiosConfig'; // Adjust the import path as necessary
import styles from './MySongsPage.module.css';
import useNavigation from '../hooks/useNavigation'; 
import { Helmet } from 'react-helmet';

function MySongsPage() {
    const [myFavorites, setMyFavorites] = useState([]);
    const [loading, setLoading] = useState(false);
    const handleNavigate = useNavigation();

    //console.log('being rendered');

    useEffect(() => {
        setLoading(true);
        authenticatedAxios.get('/api/favorites/')
            .then(response => {
                setMyFavorites(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching my favorites:', error);
                setLoading(false);
            });
    }, []); // Empty dependency array means this effect runs once on mount

    if (loading) return <div className={styles.loading}>Loading...</div>;

    // Filter out deleted favorites before mapping
    const activeFavorites = myFavorites
        .filter(favorite => !favorite.deleted)
        .sort((a, b) => new Date(b.added_on) - new Date(a.added_on)); // Sort by added_on in descending order

    return (
        
        <div className={styles.artistPage}>
        <Helmet>
            <title>Favori Akorlarım - En Sevdiğim Gitar Akorları</title>
            <link rel="canonical" href={`https://www.melodikakor.com/favori-akorlarim`} />
            <meta name="description" content="En sevdiğim gitar akorlarını keşfedin. En çok sevdiğim şarkıların akorları ve sanatçı bilgileri ile dolu bir koleksiyon." />
            <meta name="keywords" content="favori akorlar, gitar akorları, en sevdiğim şarkılar, akor koleksiyonu" />
            <meta property="og:title" content="Favori Akorlarım - En Sevdiğim Gitar Akorları" />
            <meta property="og:description" content="En sevdiğim gitar akorlarını ve şarkıları keşfedin. Detaylı akor bilgileri ve sanatçı hikayeleri." />
            <meta property="og:type" content="music.playlist" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content="path_to_your_playlist_cover_image.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Favori Akorlarım - En Sevdiğim Gitar Akorları" />
            <meta name="twitter:description" content="Favori gitar akorlarım arasında gezinin ve en sevdiğim şarkıların detaylarını öğrenin." />
            <meta name="twitter:image" content="path_to_your_twitter_cover_image.jpg" />
            <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "MusicPlaylist",
                "name": "Favori Akorlarım",
                "description": "Favori gitar akorlarım ve şarkılarım koleksiyonu.",
                "track": activeFavorites.map(favorite => ({
                    "@type": "MusicRecording",
                    "name": favorite.song.song_name,
                    "byArtist": {
                        "@type": "MusicGroup",
                        "name": favorite.song.artist_name
                    }
                }))
            })}
            </script>
        </Helmet>
        <section aria-labelledby="favorites-header" className={styles.artistNameContainer}>
            <h1 id="favorites-header" className={styles.artistName}>Favori Akorlarım</h1>
            <i className={`fas fa-heart ${styles.heartIcon}`} aria-hidden="true"></i>
        </section>
        <nav aria-label="Favorite Songs">
            <ul>
                {activeFavorites.map((favorite) => (
                    <li key={favorite.id}>
                        <div className={styles.tabInfoContainer}>
                            <a href={`/akorlar/${favorite.song.unique_artist_id}`} className={styles.artist} onClick={(e) => {
                                e.preventDefault();
                                handleNavigate(`/akorlar/${favorite.song.unique_artist_id}`);
                            }}>
                                {favorite.song.artist_name}
                            </a>
                            <span className={styles.artist}> - </span>
                            <a href={`/akor/${favorite.song.unique_song_id}`} className={styles.song} onClick={(e) => {
                                e.preventDefault();
                                handleNavigate(`/akor/${favorite.song.unique_song_id}`);
                            }}>
                                {favorite.song.song_name}
                            </a>
                        </div>
                    </li>
                ))}
            </ul>
        </nav>
    </div>
    
    );
}

export default MySongsPage;
