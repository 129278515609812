import React from 'react';

function ChordTunerWidget() {
  return (
    <iframe
      src="https://guitarapp.com/tuner.html?embed=true"
      allow="microphone"
      title="GuitarApp Online Tuner"
      style={{
        width: '360px',
        height: '520px',
        borderStyle: 'none',
        borderRadius: '4px',
      }}
    ></iframe>
  );
}

export default ChordTunerWidget;
