/** @jsxImportSource @emotion/react */
import React, { useState, useEffect } from 'react';
import { nonAuthenticatedAxios } from '../utils/axiosConfig';
import styles from './SearchBar.module.css';
import useNavigation from '../hooks/useNavigation';

function SearchBar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [placeholder, setPlaceholder] = useState('Hangi şarkı veya sanatçıyı arıyorsun?');
  const handleNavigate = useNavigation();
  const searchBarRef = React.useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchBarRef.current && !searchBarRef.current.contains(event.target)) {
        setSearchResults([]);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm && searchTerm.length > 2) {
        try {
          const response = await nonAuthenticatedAxios.get('/api/akor/', {
            params: { search_term: searchTerm },
          });
          setSearchResults(response.data);
        } catch (error) {
          console.error(error);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handleMediaQueryChange = (e) => {
      if (e.matches) {
        setPlaceholder('Şarkı veya sanatçı arat');
      } else {
        setPlaceholder('Hangi şarkı veya sanatçıyı arıyorsun?');
      }
    };

    handleMediaQueryChange(mediaQuery); // Set initial value
    mediaQuery.addListener(handleMediaQueryChange); // Add listener for future changes

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);

  const handleSongClick = (unique_song_id) => {
    setSearchTerm('');
    setSearchResults([]);
    handleNavigate(`/akor/${unique_song_id}`);
  };

  return (
    <div className={styles.searchContainer} ref={searchBarRef}>
      <i className={`fas fa-search ${styles.searchIconStyle}`}></i>
      <input
        type="text"
        className={styles.searchInputStyle}
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder={placeholder}
      />
      <ul className={styles.searchResultListStyle}>
        {searchResults.map((result) => (
          <div key={result.unique_song_id}>
            <i className={`fas fa-search ${styles.searchIconStyle}`}></i>
            <li
              key={result.unique_song_id}
              className={styles.searchResultItemStyle}
              onClick={() => handleSongClick(result.unique_song_id)}
            >
              {result.artist_name} - {result.song_name}
            </li>
          </div>
        ))}
      </ul>
    </div>
  );
}

export default SearchBar;
