import React from 'react';

function YoutubeWidget({ videoLink }) {
  // Construct the full YouTube embed URL using the videoLink
  const videoSrc = `https://www.youtube.com/embed/${videoLink}?rel=0`;

  return (
    videoLink ? (
      <iframe
        width="560"
        height="315"
        src={videoSrc}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen>
      </iframe>
    ) : null
  );
}

export default YoutubeWidget;
