import React from 'react';
import useNavigation from '../hooks/useNavigation'; 
import styles from './RecommendedSong.module.css';
import { Link } from 'react-router-dom';

function RecommendedSong({ recommendedSong }) {
    const { similar_songs, ranked_songs } = recommendedSong;
    const handleNavigate = useNavigation();

    return (
        <div className={styles.container}>
            <h2 className={styles.subheader}>Önerilen Şarkılar</h2>
            <div className={styles.songContainer}>
                {/* Render ranked songs */}
                {ranked_songs && ranked_songs.map((song, index) => (
                    <div key={index} className={styles.songItem}>
                        <Link  className={styles.artistItemText} to={`/akorlar/${song.unique_artist_id}`} >{song.artist_name}</Link>
                        <p className={styles.songItemText}>-</p>
                        <Link  className={styles.songItemText} to={`/akor/${song.unique_song_id}`} >{song.song_name}</Link>
                    </div>
                ))}
                {/* Render similar songs */}
                {similar_songs && similar_songs.map((song, index) => (
                    <div key={index} className={styles.songItem}>
                        <Link  className={styles.artistItemText} to={`/akorlar/${song.unique_artist_id}`} >{song.artist_name}</Link>
                        <p className={styles.songItemText}>-</p>
                        <Link  className={styles.songItemText} to={`/akor/${song.unique_song_id}`} >{song.song_name}</Link>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default RecommendedSong;
