import React from 'react';
import useNavigation from '../hooks/useNavigation'; 
import styles from './SimilarArtists.module.css';

function SimilarArtists({ similarArtists }) {
    const handleNavigate = useNavigation();

    const sortedArtists = similarArtists.sort((a, b) => a.english_artist_name.localeCompare(b.english_artist_name));

    return (
        <div className={styles.similarArtistsContainer}>
            {sortedArtists.map((artist, index) => (
                <div key={index} className={styles.artistItem} onClick={() => handleNavigate(`/akorlar/${artist.unique_artist_id}`)}>
                    <span>{artist.artist_name}</span>
                    {/* Optionally, add artist images here */}
                </div>
            ))}
        </div>
    );
}

export default SimilarArtists;
