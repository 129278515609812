// AppRouter.js
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import ArtistPage from '../pages/ArtistPage';
import SongPage from '../pages/SongPage';
import PrivateRoute from '../contexts/PrivateRoute'; // Import PrivateRoute
import AuthPage from '../pages/AuthPage';
import MySongsPage from '../pages/MySongsPage';
import MyListPage from '../pages/MyListPage';


const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/akorlar/:artistName" element={<ArtistPage />} />
      <Route path="/akor/:unique_song_id" element={<SongPage/>} />
      <Route path="/login" element={<AuthPage />} />
      <Route path="/signup" element={<AuthPage />} />
      <Route path="/favori-akorlarim" element={<MySongsPage />} />
      <Route path="/akor-calma-listeleri/:genre" element={<MyListPage />} />
      
    </Routes>
  );
};

export default AppRouter;
