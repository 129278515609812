// ChordDiagram.js
import React from 'react';
import styles from './ChordDiagram.module.css';

const ChordDiagram = ({ chordName, positions, capo }) => {
  const strings = 6;
  const fretsDisplayed = 4;

  const getStringPosition = (stringNumber) => {
    // Calculate the string position based on its order
    return `${120 - (100 / (strings - 1 )) * stringNumber}%`;
  };

  const getFretPosition = (fretNumber) => {
    // Calculate fret position in the middle of the space between frets
    // The first fret (1) should be placed between the nut and the first fret bar
    if(capo>0){
      return `${(100 / (fretsDisplayed )) * (fretNumber + 0.5)}%`;
    }
    else{
      return `${(100 / (fretsDisplayed )) * (fretNumber -0.5)}%`;
    }
  };
  
  const fingerNumberPosition = () => {
    if (capo > 0) {
      return getFretPosition(fretsDisplayed - 0.5); // Adjusted to +0.5 to match the other condition
    } else {
      return getFretPosition(fretsDisplayed + 0.5); // Same here, because the position is not capo-dependent
    }
  };


  return (
    <div className={styles.chordDiagram}>
        <div className={styles.chordName}>{chordName}</div>
        <div className={styles.fretboard}>
          {capo > 0 && (
            <>
              <div className={styles.capo} />
              <div className={styles.capoLabel}>{`${capo}fr`}</div>
            </>
          )}
          {/* Render the strings */}
          {Array.from({ length: strings }, (_, index) => (
            <div key={`string-${index}`} className={styles.string} style={{ left: getStringPosition(index + 1) }} />
          ))}
          {/* Render the frets */}
          {Array.from({ length: fretsDisplayed }, (_, index) => (
            <div key={`fret-${index}`} className={styles.fret} style={{ top: `${(100 / (fretsDisplayed )) * (index + 1)}%` }} />
          ))}
          {/* Render the chord dots */}
          {positions.map((pos, index) => {
            // Adjust the dot position if there is a capo
            const fretPosition = capo ? pos.fret + 1 : pos.fret;
            return (
                <div
                key={`dot-${index}`}
                className={styles.dot}
                style={{
                  left: getStringPosition(pos.string),
                  top: getFretPosition(pos.fret),
                }}
              >
              </div>
            );
          })}
          {positions.map((pos, index) => (
            <div
              key={`finger-${index}`}
              className={styles.fingerNumber}
              style={{
                left: getStringPosition(pos.string),
                top: fingerNumberPosition(), // Position at the bottom of the last fret
              }}
            >
              {pos.finger}
            </div>
          ))}
        </div>
    </div>
  );
};

export default ChordDiagram;
