import React, { useState, useEffect, useMemo } from 'react';
import styles from './TopSongs.module.css';
import useApi from '../hooks/useApi';
import useNavigation from '../hooks/useNavigation'; 
import { Link } from 'react-router-dom';



const TopSongs = ({subcategory, category_type, title, howManyRows }) => {

  const [filter, setFilter] = useState(subcategory); // State to manage the filter
  const handleNavigate = useNavigation();

  const params = useMemo(() => ({ subcategory: subcategory, category_type: category_type, howManyRows:howManyRows }), [subcategory, category_type, howManyRows]);
  const { data, error, loading } = useApi('/api/song-category/', params);

  const handleClickFilter = (newFilter) => {
    setFilter(newFilter);
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerContainer}>
          <h2 className={styles.title}>{title}</h2>
      </div>
      <div className={styles.tabListContainer}>
        {data && data.map((tab, index) => (
          <div key={index} className={styles.tabRowItem}>
            <div className={styles.rank}>{index + 1}</div>
            <div className={styles.tabInfoContainer}>
                <Link to={`/akorlar/${tab.unique_artist_id}`} className={styles.artist}>{tab.artist_name}</Link>
                <Link to={`/akor/${tab.unique_song_id}`} className={styles.song}>{tab.song_name}</Link>
                <span className={styles.hits}>{tab.youtube_standardized_view_count} hits</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TopSongs;
