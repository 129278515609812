import React from 'react';
import useNavigation from '../hooks/useNavigation';
import styles from './ArtistsSongs.module.css';
import { Link } from 'react-router-dom';


function ArtistsSongs({ artistsSongs }) {
    const handleNavigate = useNavigation();

    // Sort artistsSongs by youtube_view_rank_by_artist
    const sortedSongs = artistsSongs.sort((a, b) => a.youtube_view_rank_by_artist - b.youtube_view_rank_by_artist);

    return (
        <div className={styles.container}>
            <div className={styles.songList}>
                {sortedSongs.map((song, index) => (
                    <div key={index} className={styles.songItemContainer}>
                        <Link 
                            to={`/akor/${song.unique_song_id}`}
                            className={styles.songItem}
                        >
                            {song.song_name}
                        </Link>
                        <span className={styles.hits}>
                            {song.youtube_standardized_view_count} hits
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ArtistsSongs;
