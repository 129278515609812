// SongDetails.jsx
import React, { useState, useMemo, useContext, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import styles from './SongPage.module.css'; // Make sure to create this CSS module file
import useApi from '../hooks/useApi'; // Import your useApi hook
import RecommendedSong from '../components/RecommendedSong.js';
import ChordDiagramTable from '../components/ChordDiagramTable';
import { transposeChord } from '../utils/transposeChord';
import SongVersionList from '../components/SongVersionList';
import CommentsSection from '../components/comments/CommentsSection';
import ChordTransposer from '../components/ChordTransposer';
import ChordTunerWidget from '../components/ChordTunerWidget';
import YoutubeWidget from '../components/YoutubeWidget';
import { AuthContext } from '../contexts/AuthContext';
import { authenticatedAxios, nonAuthenticatedAxios } from '../utils/axiosConfig';
import { Helmet } from 'react-helmet';


function SongPage() {
    const { unique_song_id } = useParams();
    const howManyRows = 50
    const params = useMemo(() => ({ unique_song_id: unique_song_id, howManyRows:howManyRows }), [unique_song_id,howManyRows]);
    const navigate = useNavigate();
    const { isAuthenticated } = useContext(AuthContext);

    const { data: songDetails, error, loading } = useApi('/api/akor/', params);
    const { data: recommendedSong } = useApi('/api/song-category-similar-songs/', params);
    const { data: comments } = useApi('/api/comment/?unique_song_id=${unique_song_id}', params);
    const safeComments = Array.isArray(comments) ? comments : [];

    const [songData, setSongData] = useState([]);
    const [currentVersion, setCurrentVersion] = useState('1');

    // Arranged for mobil and pc below
    const [fontSize, setFontSize] = useState(12);
    const increaseFontSize = () => setFontSize(fontSize + 2);
    const decreaseFontSize = () => setFontSize(fontSize - 2);
    const [showChordTuner, setShowChordTuner] = useState(false);
    const [showChordDiagram, setShowChordDiagram] = useState(true);
    const [showYoutube, setShowYoutube] = useState(false);
    const toggleChordTuner = () => setShowChordTuner(!showChordTuner);
    const toggleChordDiagram = () => setShowChordDiagram(!showChordDiagram);
    const toggleYoutube = () => setShowYoutube(!showYoutube);
    const [rating, setRating] = useState(0);
    const [tempRating, setTempRating] = useState(null);
    const [isFavorite, setIsFavorite] = useState(false);

    const [averageRating, setAverageRating] = useState(0);
    const details = songDetails && songDetails.length > 0 ? songDetails[0] : null;
    const [transposeInterval, setTransposeInterval] = useState(0);
    const [transposedChords, setTransposedChords] = useState([]);


    const [isAutoScrolling, setIsAutoScrolling] = useState(false);
    const [scrollInterval, setScrollInterval] = useState(null);
    const scrollSpeed = 100;


    const handleTransposeUp = () => {
      setTransposeInterval(transposeInterval + 1);
    };


    const handleTransposeDown = () => {
      setTransposeInterval(transposeInterval - 1);
    };

    
    // on song change
    useEffect(() => {
      setTransposeInterval(0);
  }, [unique_song_id]);
  

    useEffect(() => {
      if (songDetails && songDetails.length > 0) {
          const details = songDetails[0];
          //console.log('details');
          //console.log(details);
          setCurrentVersion(details.song_version);
      }
    }, [songDetails]);


    useEffect(() => {
      if (details && details.parsed_chords) {
          try {
              const parsedData = JSON.parse(details.parsed_chords);
              if (Array.isArray(parsedData)) { // Confirm it's an array
                  setSongData(parsedData);
              } else {
                  setSongData([]);
              }
          } catch (error) {
              setSongData([]);
          }
      }
    }, [details]);

    // Determine and set initial font size based on screen size
    useEffect(() => {
      const mediaQuery = window.matchMedia("(max-width: 768px)");
      const handleMediaQueryChange = (e) => setFontSize(e.matches ? 12 : 14);

      handleMediaQueryChange(mediaQuery); // Set initial value
      mediaQuery.addListener(handleMediaQueryChange); // Add listener for future changes

      return () => {
          mediaQuery.removeListener(handleMediaQueryChange); // Cleanup listener on unmount
      };
  }, []);
  

    useEffect(() => {
      if (details && details.unique_chords) {
          const newChords = details.unique_chords.map(chord => 
              transposeChord(chord, transposeInterval));
          setTransposedChords(newChords);
      }
  }, [details, transposeInterval]);
  
    const fetchFavoriteStatus = useCallback(async () => {
      if (isAuthenticated) {
        try {
          const response = await authenticatedAxios.get(`/api/favorites/status/${unique_song_id}/`);
          setIsFavorite(response.data.isFavorite);
        } catch (error) {
          console.error('Error fetching favorite status:', error);  
        }
      } else {
        setIsFavorite(false);
      }
    }, [unique_song_id, isAuthenticated]);
  
  
    useEffect(() => {
      fetchFavoriteStatus();
    }, [fetchFavoriteStatus]);
  
  
    useEffect(() => {
       if (songDetails && songDetails.length > 0) {
         const initialVersion = songDetails[0].song_version;
         setCurrentVersion(initialVersion);
       }
       if (details && details.parsed_chords) {
         setSongData(details.parsed_chords);
       }
    }, [songDetails,details]);


    const handleVersionChange = (version, uniqueSongId) => {
        setCurrentVersion(version);
        navigate(`/akor/${uniqueSongId}`);
        setTransposeInterval(0);

    };

    const toggleFavorite = async () => {

      if (!isAuthenticated) {
        navigate('/login');
        return;
      }

      const method = isFavorite ? 'delete' : 'post';
      const url = isFavorite ? `/api/favorites/${unique_song_id}/` : '/api/favorites/';
      const data = method === 'post' ? { unique_song_id: unique_song_id } : null;

      if (method === 'post') {
      }

      try {
        const response = await authenticatedAxios({
          method,
          url,
          ...(data && { data }),
        });
        setIsFavorite(method === 'post' ? true : false);
        await fetchFavoriteStatus();
      } catch (error) {
          if (error.response) {
            console.error('Error data:', error.response.data);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
          console.error('Error config:', error.config);
        }
    };

    const fetchUserRating = useCallback(async () => {
        if (isAuthenticated) {
            try {
                const response = await authenticatedAxios.get(`/api/ratings/${unique_song_id}/`);
                setRating(response.data.rating);
            } catch (error) {
                console.error('Error fetching user rating:', error);
            }
        }
    }, [unique_song_id, isAuthenticated]);

    useEffect(() => {
        fetchUserRating();
    }, [fetchUserRating]);


    const lyricsContainerRef = useRef(null);

useEffect(() => {
    let autoScroll;
    if (isAutoScrolling) {
        const chordsContainer = lyricsContainerRef.current;
        if (chordsContainer) {
            const maxScroll = chordsContainer.offsetTop + chordsContainer.offsetHeight - window.innerHeight;
            autoScroll = setInterval(() => {
                if (window.scrollY < maxScroll) {
                    window.scrollBy(0, 1);
                } else {
                    setIsAutoScrolling(false);
                }
            }, scrollSpeed);
        }
    } else {
        clearInterval(autoScroll);
    }
    return () => clearInterval(autoScroll);
}, [isAutoScrolling, scrollSpeed]);
  
    
    const fetchAverageRating = useCallback(async () => {
        if (unique_song_id) {
            try {
                const response = await nonAuthenticatedAxios.get(`/api/average-rating/${unique_song_id}/`);
                setAverageRating(response.data.average_rating);
            } catch (error) {
                console.error('Error fetching average rating:', error);
            }
        }
    }, [unique_song_id]);

    useEffect(() => {
      fetchAverageRating();
    }, [fetchAverageRating]);
  




  
    if (loading) {
        return <div className={styles.loading}>Yükleniyor...</div>;
    }
  
  
    if (songDetails.length === 0) {
        return <div className={styles.notFound}>Şarkı detayları bulunamadı.</div>;
    }
  
  
    const handleNavigate = (path) => {
        navigate(path);
    };
      
    
    const youtubeLink = details.youtube_link;
    let youtubeVideoId = null
    if (youtubeLink){
      youtubeVideoId = new URLSearchParams(new URL(youtubeLink).search).get('v');
    }
  
    const submitRating = async (newRating) => {
        if (!isAuthenticated) {
          navigate('/login');
          return;
        }
    
        try {
          const url = `/api/ratings/`; 
          const data = { unique_song_id: unique_song_id, rating: newRating };
          const response = await authenticatedAxios.post(url, data);
          setRating(newRating);
        } catch (error) {
          console.error('Error submitting rating:', error);
        }
    };

    const title = `${details.song_name} - ${details.artist_name} Akorları ve Sözleri`;
    const keywords = `${details.song_name}, ${details.artist_name}, akorlar, gitar akorları, orjinal ton, klasik gitar, elektro gitar, piyano akorları, şarkı sözleri`;
    const description = `${details.artist_name} - ${details.song_name} akor, kolay ve orjinal ton gitar akorları, ${details.song_name.toLowerCase()} şarkısını çalmayı öğrenin! Uygun enstrümanlar: klasik gitar, ukulele, elektro gitar ve piyano.`;



    return (
      
        <div>
          <Helmet>
            <title>{title}</title>
            <link rel="canonical" href={`https://www.melodikakor.com/akor/${details.unique_song_id}`} />
            <meta name="description" content={description} />
            <meta name="keywords" content={keywords} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:type" content="music.song" />
            <meta property="og:url" content={window.location.href} />
            <meta property="og:image" content={details.coverImage || 'default_image_path.jpg'} />
            <meta property="fb:app_id" content="your_facebook_app_id" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="twitter:image" content={details.coverImage || 'default_twitter_image_path.jpg'} />
            <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "MusicComposition",
                "name": details.song_name,
                "description": `${details.song_name}, best known for its melodious tune and profound lyrics, is beautifully composed by ${details.artist_name}. Learn and play with our easy guitar chords, use our online tuner, and watch tutorial videos and transpose the chords.`,
                "url": window.location.href,
                "composer": {
                    "@type": "Person",
                    "name": details.artist_name
                },
                //"datePublished": details.release_date, // Assuming `release_date` is available in your details
                //"genre": details.genre, // Assuming `genre` is available in your details
                "includedComposition": {
                    "@type": "MusicRecording",
                    "name": details.song_name,
                    "byArtist": {
                        "@type": "MusicGroup",
                        "name": details.artist_name
                    },
                    "recordingOf": {
                        "@type": "MusicComposition",
                        "name": details.song_name
                    }
                },
                "musicArrangement": `${details.song_name} chords arrangement, including an online tuner and video tutorials`,
            })}
            </script>
          </Helmet>
              
            <header className={styles.topRowContainer}>
                <h1 className={styles.titleContainer}>
                    <Link to={`/akorlar/${details.unique_artist_id}`} className={styles.artistName}>
                        {details.artist_name}
                    </Link>
                    <span className={styles.songName}> - {details.song_name}</span>
                </h1>
                <div className={styles.favContainer}>
                    <div className={styles.favSubContainer} onClick={toggleFavorite}>
                        <p className={styles.favoriText}>{isFavorite ? 'FAVORILERDEN ÇIKAR' : 'FAVORILERE EKLE'}</p>
                        <i className={`${isFavorite ? 'fas' : 'far'} fa-heart`} style={{ color: isFavorite ? 'red' : 'grey' }}></i> 
                    </div>
                </div>
            </header>
              
            <main className={styles.pageContainer}>
              <section aria-labelledby="song-info" className={styles.menuContainer}>
                  <h2 id="song-info" className={styles.visually_hidden}>Şarkı Bilgileri</h2>
                  <SongVersionList
                    versionsInfo={details.versions_info}
                    currentVersion={currentVersion}
                    onVersionChange={handleVersionChange}
                    averageRating={averageRating}
                  />
                      <p className={styles.menuElements}>Kapo: {details.capo_info}</p>
                      <p className={styles.menuElements}>Orjinal ton: {details.original_key}</p>
                      <p className={styles.menuElements}>Ritim: {details.rhythm_info_rows}</p>
                  
              </section>
              <section className={styles.chordsOfSongContainer} onClick={toggleChordDiagram} aria-labelledby="chords-title">
                  <h2 className={styles.chordsOfSongTitle} >Akorlar</h2>
                  {showChordDiagram && <ChordDiagramTable  transposedChords={transposedChords}/>}
              </section>

              <section aria-labelledby="interactive-features" className={styles.menuContainer2}>
                <h2 id="interactive-features" className={styles.visually_hidden}>İnteraktif Özellikler</h2>
                
                <div className={styles.interactiveFeaturesContainer}>
                    <h3 className={styles.visually_hidden}>Gitar Akordu Tuner</h3>
                    <button className={styles.clickableElements} onClick={toggleChordTuner} aria-label="Gitarı akort et">
                        <span className={styles.fontSizeContainerTitle}>Tuner</span>
                        <i className="fas fa-guitar" style={{ marginLeft: '3px', color:'black'}} aria-hidden="true"></i> 
                    </button>
                </div>

                {youtubeVideoId && (
                     <div className={styles.interactiveFeaturesContainer}>
                        <h3 className={styles.visually_hidden}>YouTube Video</h3>
                        <button className={styles.clickableElements} onClick={toggleYoutube} aria-label="YouTube'da videoyu izle">
                            <span className={styles.fontSizeContainerTitle}>Dinle</span>
                            <i className="fab fa-youtube" style={{ marginLeft: '3px', color: 'red'}} aria-hidden="true"></i>
                        </button>
                    </div>
                )}
                
                <div className={styles.interactiveFeaturesContainer}>
                    <h3 className={styles.visually_hidden}>Otomatik Kaydırma</h3>
                    <button className={styles.clickableElements} onClick={() => setIsAutoScrolling(!isAutoScrolling)} aria-label={isAutoScrolling ? "Otomatik kaydırmayı durdur" : "Otomatik kaydır"}>
                        <span className={styles.fontSizeContainerTitle}>{isAutoScrolling ? 'Durdur' : 'Kaydır'}</span>
                        <i className={isAutoScrolling ? "fas fa-stop" : "fas fa-arrow-down"} style={{ color: 'black', marginLeft: '1px'}} aria-hidden="true"></i>
                    </button>
                </div>
                
                <div className={styles.interactiveFeaturesContainer}>
                    <h3 className={styles.visually_hidden}>Font Boyutu Ayarı</h3>
                    <div className={styles.fontSizeContainer} aria-label="Font boyutunu ayarla">
                        <span className={styles.fontSizeContainerTitle}>Font</span> 
                        <button className={styles.fontSizeButton} onClick={increaseFontSize} aria-label="Font boyutunu büyüt">
                            <i className="fas fa-plus" style={{ fontSize: '12px', margin:'0px',padding:'0px'}} aria-hidden="true"></i>
                        </button>
                        <button className={styles.fontSizeButton} onClick={decreaseFontSize} aria-label="Font boyutunu küçült">
                            <i className="fas fa-minus" style={{ fontSize: '12px', margin:'0px',padding:'0px'}} aria-hidden="true"></i>
                        </button>
                    </div>
                </div>

                <div className={styles.interactiveFeaturesContainer}>
                    <h3 className={styles.visually_hidden}>Akor Transpoze Ayarı</h3>
                    <div className={styles.transposeContainer} aria-label="Transpoze ayarlarını değiştir">
                        <div className={styles.transposeLabelContainer}>
                            <span className={styles.fontSizeContainerTitle}>Transpoze</span>
                            {transposeInterval !== 0 && <span className={styles.transposeLevel}>{transposeInterval}</span>}
                        </div>
                        <button className={styles.fontSizeButton} onClick={handleTransposeUp} aria-label="Transpoze arttır">
                            <i className="fas fa-plus" style={{ fontSize: '12px'}} aria-hidden="true"></i>
                        </button>
                        <button className={styles.fontSizeButton} onClick={handleTransposeDown} aria-label="Transpoze azalt">
                            <i className="fas fa-minus" style={{ fontSize: '12px'}} aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </section>
              {showChordTuner && <ChordTunerWidget/>}
              {showYoutube &&  <YoutubeWidget videoLink={youtubeVideoId}/>}
              <div  className={styles.lyricsContainer} style={{ fontSize: `${fontSize}px` }}>
                  <ChordTransposer songData={songData} interval={transposeInterval} />
              </div>
              <div ref={lyricsContainerRef} className={styles.ratingContainer}>
                <p className={styles.ratingTitle} >Akorları iyileştirmemize yardımcı olmak için bu versiyonu değerlendirebilirsiniz! </p>
                  {[...Array(5)].map((_, i) => (
                    <span key={i}>
                      <i
                        className={`fas fa-star ${i < (tempRating || rating) ? 'filled' : ''}`}
                        style={{ color: i < (tempRating || rating) ? 'orange' : 'grey' }}
                        onMouseEnter={() => setTempRating(i + 1)}
                        onMouseLeave={() => setTempRating(null)}
                        onClick={() => submitRating(i + 1)}
                      />
                    </span>
                  ))}
                {rating > 0 && <p className={styles.ratingTitle}> Oyunuz için teşekkürler!</p>}
              </div>
              <div className={styles.commentsSectionContainer}>
                <CommentsSection comments={safeComments} />
              </div>
            </main>
            <RecommendedSong recommendedSong={recommendedSong} />
        </div>
    );
}

export default SongPage;
