import React from 'react';
import styles from './CommentList.module.css';

const formatTurkishDate = (datetimeString) => {
  const months = [
    "Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran",
    "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"
  ];
 
  let date = new Date(datetimeString);

  // If the date is invalid, set it to the current date. This is necessary for new created comnments.
  if (isNaN(date.getTime())) {
    date = new Date();
  }

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return ` ${day.toString().padStart(2, '0')} ${month} ${year}`;
};


const CommentList = ({ comments }) => {
  return (
    <div className={styles.commentList}>
      
      {comments.map((comment, index) =>{
        //console.log(comment);
        return(
          <div key={index} className={styles.commentItem}>
            <p className={styles.userComment}>{comment.comment}</p>
            <div  className={styles.commentInfo}>
              <p className={styles.userName}>{comment.user_name}</p>
              <p className={styles.commentDate}>{formatTurkishDate(comment.created_at)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CommentList;
