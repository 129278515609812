import { useState, useEffect } from 'react';
import {nonAuthenticatedAxios} from '../utils/axiosConfig';

const useApi = (url, params = {}) => {
  const [data, setData] = useState([]); // Initialize as an empty array
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);


  const queryParams = JSON.stringify(params);
  
  useEffect(() => {
    let isMounted = true;
    setLoading(true);


    nonAuthenticatedAxios.get(url, { params: JSON.parse(queryParams) })
      .then(response => {
        if (isMounted) {
          setData(response.data);
        }
      })
      .catch(err => {
        if (isMounted) setError(err);
      })
      .finally(() => {
        if (isMounted) setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [url, queryParams]);

  return { data, error, loading };
};

export default useApi;
