import React, { createContext, useState, useEffect, useCallback,useMemo } from 'react';
import { authenticatedAxios, nonAuthenticatedAxios } from '../utils/axiosConfig';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    let visitorId = Cookies.get('visitorId');
    if (!visitorId) {
      visitorId = uuidv4();
      Cookies.set('visitorId', visitorId, { expires: 365 });
    }
    if (token) {
      fetchUserDetails();
    }
  }, []);

  const fetchUserDetails = useCallback(async () => {
    const token = localStorage.getItem('token');
    if (!token) return;
    try {
      const response = await authenticatedAxios.get('/auth/user_details/');
      setUser(response.data);
    } catch (error) {
      console.error('Error fetching user details:', error);
      logout();
    }
  }, []);

  const login = useCallback(async (email, password) => {
    try {
      const response = await nonAuthenticatedAxios.post('/auth/login/', { email, password });
      const { token, ...userInfo } = response.data;
      localStorage.setItem('token', token);
      authenticatedAxios.defaults.headers['Authorization'] = `Token ${token}`;
      setUser(userInfo);
    } catch (error) {
      console.error('Error logging in:', error);
      throw error;  // Ensure to throw the error to handle it in the calling function
    }
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    delete authenticatedAxios.defaults.headers['Authorization'];
    setUser(null);
  }, []);

  const authValue = useMemo(() => ({
    isAuthenticated: !!user,
    user,
    login,
    logout,
    fetchUserDetails
  }), [user, login, logout, fetchUserDetails]);

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>;
};
