
// SongDetails.jsx
import React from 'react';
import ChordDiagram from './ChordDiagram';


function ChordDiagramTable({transposedChords}) {

  const chordsData = [
    
      {
        "chordName": "A",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 3, "fret": 2, "finger": 1 },
          { "string": 2, "fret": 2, "finger": 3 }
        ],
        "capo": 0
      },
      {
        "chordName": "Am",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 3, "fret": 2, "finger": 3 },
          { "string": 2, "fret": 1, "finger": 1 }
        ],
        "capo": 0
      },
      {
        "chordName": "A7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 2, "fret": 2, "finger": 1 }
        ],
        "capo": 0
      },
      {
        "chordName": "A5",
        "positions": [
          { "string": 4, "fret": 2, "finger": 1 },
          { "string": 3, "fret": 2, "finger": 2 }
        ],
        "capo": 0
      },
      {
        "chordName": "Ab",
        "positions": [
          { "string": 5, "fret": 2, "finger": 3 },
          { "string": 4, "fret": 2, "finger": 4 },
          { "string": 3, "fret": 1, "finger": 2 },

        ],
        "capo": 4
      },
      {
        "chordName": "Amaj7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 3, "fret": 1, "finger": 1 },
          { "string": 2, "fret": 2, "finger": 3 }
        ],
        "capo": 0
      },
      {
        "chordName": "Am7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 2, "fret": 1, "finger": 1 }
        ],
        "capo": 0
      },
      {
        "chordName": "A#",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 },
          { "string": 2, "fret": 2, "finger": 2 }
        ],
        "capo": 1
      },
      {
        "chordName": "A#m",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 },
          { "string": 2, "fret": 1, "finger": 2 }
        ],
        "capo": 1
      },
      {
        "chordName": "A#7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 2, "fret": 2, "finger": 1 }
        ],
        "capo": 1
      },
      {
        "chordName": "A#5",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 }
        ],
        "capo": 1
      },
      {
        "chordName": "A#b",
        "positions": [
          { "string": 5, "fret": 2, "finger": 3 },
          { "string": 4, "fret": 2, "finger": 4 },
          { "string": 3, "fret": 1, "finger": 2 },

        ],
        "capo": 5
      },
      {
        "chordName": "A#maj7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 1, "finger": 2 },
          { "string": 2, "fret": 2, "finger": 4 }
        ],
        "capo": 1
      },
      {
        "chordName": "A#m7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 2, "fret": 1, "finger": 2 }
        ],
        "capo": 1
      },
      {
        "chordName": "B",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 },
          { "string": 2, "fret": 2, "finger": 2 }
        ],
        "capo": 2
      },
      {
        "chordName": "Bm",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 },
          { "string": 2, "fret": 1, "finger": 2 }
        ],
        "capo": 2
      },
      {
        "chordName": "B7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 2 },
          { "string": 2, "fret": 2, "finger": 1 }
        ],
        "capo": 2
      },
      {
        "chordName": "B5",
        "positions": [
          { "string": 4, "fret": 2, "finger": 1 },
          { "string": 3, "fret": 2, "finger": 2 }
        ],
        "capo": 2
      },
      {
        "chordName": "Bb",
        "positions": [
          { "string": 4, "fret": 3, "finger": 3 },
          { "string": 3, "fret": 3, "finger": 4 },
          { "string": 2, "fret": 3, "finger": 2 },
          { "string": 1, "fret": 1, "finger": 1 }
        ],
        "capo": 0
      },
      {
        "chordName": "Bmaj7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 1, "finger": 2 },
          { "string": 2, "fret": 2, "finger": 4 }
        ],
        "capo": 2
      },
      {
        "chordName": "Bm7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 2, "fret": 1, "finger": 2 }
        ],
        "capo": 2
      },
      
      
        {
            "chordName": "C",
            "positions": [
                { "string": 5, "fret": 3, "finger": 3 },
                { "string": 4, "fret": 2, "finger": 2 },
                { "string": 2, "fret": 1, "finger": 1 }
            ],
            "capo": 0
        },
        {
            "chordName": "Cm",
            "positions": [
                { "string": 4, "fret": 2, "finger": 3 },
                { "string": 3, "fret": 2, "finger": 4 },
                { "string": 2, "fret": 1, "finger": 2 }
            ],
            "capo": 3
        },
        {
            "chordName": "C7",
            "positions": [
              { "string": 5, "fret": 3, "finger": 3 },
              { "string": 4, "fret": 2, "finger": 2 },
              { "string": 2, "fret": 1, "finger": 1 },
              { "string": 3, "fret": 3, "finger": 1 }
            ],
            "capo": 0
        },
        {
            "chordName": "C5",
            "positions": [
                { "string": 2, "fret": 1, "finger": 1 },
  
            ],
            "capo": 0
        },
        {
            "chordName": "Cb",
            "positions": [
              { "string": 4, "fret": 2, "finger": 3 },
              { "string": 3, "fret": 2, "finger": 4 },
              { "string": 2, "fret": 2, "finger": 2 }
            ],
            "capo": 2
        },
        {
            "chordName": "Cmaj7",
            "positions": [
                { "string": 5, "fret": 3, "finger": 3 },
                { "string": 4, "fret": 2, "finger": 2 },

            ],
            "capo": 0
        },
        {
            "chordName": "Cm7",
            "positions": [
                { "string": 4, "fret": 2, "finger": 3 },
                { "string": 2, "fret": 1, "finger": 2 }
            ],
            "capo": 3
        },
        {
            "chordName": "C#",
            "positions": [
              { "string": 4, "fret": 2, "finger": 3 },
              { "string": 3, "fret": 2, "finger": 4 },
              { "string": 2, "fret": 2, "finger": 2 }
            ],
            "capo": 4
        },
        {
            "chordName": "C#m",
            "positions": [
                { "string": 4, "fret": 2, "finger": 3 },
                { "string": 3, "fret": 2, "finger": 4 },
                { "string": 2, "fret": 1, "finger": 2 }
            ],
            "capo": 4
        },
        {
            "chordName": "C#7",
            "positions": [
              { "string": 4, "fret": 2, "finger": 3 },
              { "string": 2, "fret": 2, "finger": 2 }
            ],
            "capo": 4
        },


        {
          "chordName": "C#5",
          "positions": [
              { "string": 3, "fret": 1, "finger": 1 },
              { "string": 2, "fret": 2, "finger": 2 }
          ],
          "capo": 0
      },
      {
          "chordName": "C#b",
          "positions": [
            { "string": 4, "fret": 2, "finger": 3 },
            { "string": 3, "fret": 2, "finger": 4 },
            { "string": 2, "fret": 2, "finger": 2 }
          ],
          "capo": 3
      },
      {
          "chordName": "C#maj7",
          "positions": [
              { "string": 5, "fret": 3, "finger": 4 },
              { "string": 4, "fret": 2, "finger": 3 }
          ],
          "capo": 4
      },
      {
          "chordName": "C#m7",
          "positions": [
            { "string": 4, "fret": 2, "finger": 3 },
            { "string": 2, "fret": 1, "finger": 2 }
        ],
        "capo": 4
      },
      {
          "chordName": "D",
          "positions": [
              { "string": 3, "fret": 2, "finger": 1 },
              { "string": 2, "fret": 3, "finger": 3 },
              { "string": 1, "fret": 2, "finger": 2 }
          ],
          "capo": 0
      },
      {
          "chordName": "Dm",
          "positions": [
              { "string": 3, "fret": 2, "finger": 2 },
              { "string": 2, "fret": 3, "finger": 3 },
              { "string": 1, "fret": 1, "finger": 1 }
          ],
          "capo": 0
      },
      {
          "chordName": "D7",
          "positions": [
              { "string": 3, "fret": 2, "finger": 2 },
              { "string": 2, "fret": 1, "finger": 1 },
              { "string": 1, "fret": 2, "finger": 3 }
          ],
          "capo": 0
      },

      {
        "chordName": "D5",
        "positions": [

            { "string": 3, "fret": 2, "finger": 1 },
            { "string": 2, "fret": 3, "finger": 3 }
        ],
        "capo": 0
    },
    {
        "chordName": "Dmaj7",
        "positions": [

            { "string": 3, "fret": 2, "finger": 1 },
            { "string": 2, "fret": 2, "finger": 2 },
            { "string": 1, "fret": 2, "finger": 3 }
        ],
        "capo": 0
    },
    {
        "chordName": "Dm7",
        "positions": [

            { "string": 3, "fret": 2, "finger": 2 },
            { "string": 2, "fret": 1, "finger": 1 },
            { "string": 1, "fret": 1, "finger": 1 }
        ],
        "capo": 0
    },
    {
        "chordName": "D#",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 },
          { "string": 2, "fret": 2, "finger": 2 }
        ],
        "capo": 6
    },
    {
        "chordName": "D#m",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 2, "finger": 4 },
          { "string": 2, "fret": 1, "finger": 2 }
        ],
        "capo": 6
    },
    {
        "chordName": "D#7",
        "positions": [
          { "string": 4, "fret": 2, "finger": 3 },
      
          { "string": 2, "fret": 2, "finger": 4 }
        ],
        "capo": 6
    },
    {
        "chordName": "D#5",
        "positions": [
          { "string": 4, "fret": 1, "finger": 1 },
          { "string": 3, "fret": 3, "finger": 3 },
          { "string": 2, "fret": 4, "finger": 4 }
        ],
        "capo": 0
    },
    {
        "chordName": "E",
        "positions": [
            { "string": 5, "fret": 2, "finger": 2 },
            { "string": 4, "fret": 2, "finger": 3 },
            { "string": 3, "fret": 1, "finger": 1 }
        ],
        "capo": 0
    },
    {
        "chordName": "Em",
        "positions": [
            { "string": 5, "fret": 2, "finger": 2 },
            { "string": 4, "fret": 2, "finger": 3 }
        ],
        "capo": 0
    },
    {
        "chordName": "E7",
        "positions": [
            { "string": 5, "fret": 2, "finger": 2 },
            { "string": 3, "fret": 1, "finger": 1 },
        ],
        "capo": 0
    },
    {
        "chordName": "E5",
        "positions": [
            { "string": 5, "fret": 2, "finger": 2 }
        ],
        "capo": 0
    },

    {
      "chordName": "Eb",
      "positions": [
        { "string": 4, "fret": 2, "finger": 3 },
        { "string": 3, "fret": 2, "finger": 4 },
        { "string": 2, "fret": 2, "finger": 2 }
      ],
      "capo": 6
  },
  {
      "chordName": "Emaj7",
      "positions": [
          { "string": 4, "fret": 1, "finger": 1 },
          { "string": 3, "fret": 1, "finger": 1 }
      ],
      "capo": 0
  },
  {
      "chordName": "Em7",
      "positions": [
          { "string": 5, "fret": 2, "finger": 1 }
      ],
      "capo": 0
  },
  {
      "chordName": "F",
      "positions": [
          { "string": 5, "fret": 2, "finger": 3 },
          { "string": 4, "fret": 2, "finger": 4 },
          { "string": 3, "fret": 1, "finger": 2 }
      ],
      "capo": 1
  },
  {
      "chordName": "Fm",
      "positions": [
          { "string": 5, "fret": 2, "finger": 3 },
          { "string": 4, "fret": 2, "finger": 4 }
      ],
      "capo": 1
  },
  {
      "chordName": "F7",
      "positions": [
          { "string": 5, "fret": 2, "finger": 2 },
          { "string": 3, "fret": 1, "finger": 3 }
      ],
      "capo": 1
  },
  {
      "chordName": "F5",
      "positions": [
          { "string": 5, "fret": 2, "finger": 3 }
      ],
      "capo": 1
  },
  {
      "chordName": "Fb",
      "positions": [
          { "string": 5, "fret": 2, "finger": 2 },
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 1, "finger": 1 }
      ],
      "capo": 0
  },
  {
      "chordName": "Fmaj7",
      "positions": [
          { "string": 2, "fret": 1, "finger": 1 },
          { "string": 3, "fret": 2, "finger": 2 },
          { "string": 4, "fret": 3, "finger": 3 },

      ],
      "capo": 0
  },
  {
      "chordName": "Fm7",
      "positions": [
          { "string": 5, "fret": 3, "finger": 3 }
      ],
      "capo": 1
  },


  {
    "chordName": "F#",
    "positions": [
        { "string": 5, "fret": 2, "finger": 3 },
        { "string": 4, "fret": 2, "finger": 4 },
        { "string": 3, "fret": 1, "finger": 2 }
    ],
    "capo": 2
},
{
    "chordName": "F#m",
    "positions": [
        { "string": 5, "fret": 2, "finger": 3 },
        { "string": 4, "fret": 2, "finger": 4 }
    ],
    "capo": 2
},
{
    "chordName": "F#7",
    "positions": [
        { "string": 5, "fret": 2, "finger": 2 },
        { "string": 3, "fret": 1, "finger": 3 }
    ],
    "capo": 2
},
{
    "chordName": "F#5",
    "positions": [
        { "string": 5, "fret": 2, "finger": 3 }
    ],
    "capo": 2
},

{
    "chordName": "F#maj7",
    "positions": [
        { "string": 2, "fret": 2, "finger": 2 },
        { "string": 3, "fret": 3, "finger": 4 },
        { "string": 4, "fret": 3, "finger": 3 },
        { "string": 6, "fret": 2, "finger": 1 },
    ],
    "capo": 0
},
{
    "chordName": "F#m7",
    "positions": [
        { "string": 5, "fret": 3, "finger": 3 }
    ],
    "capo": 2
},

  {
    "chordName": "G",
    "positions": [
        { "string": 6, "fret": 3, "finger": 2 },
        { "string": 5, "fret": 2, "finger": 3 },
        { "string": 1, "fret": 3, "finger": 1 }
    ],
    "capo": 0
},

  {
      "chordName": "Gm",
      "positions": [
          { "string": 5, "fret": 2, "finger": 3 },
          { "string": 4, "fret": 2, "finger": 4 }
      ],
      "capo": 3
  },
  {
      "chordName": "G7",
      "positions": [
        { "string": 6, "fret": 3, "finger": 2 },
        { "string": 5, "fret": 2, "finger": 3 },
        { "string": 1, "fret": 1, "finger": 1 }
    ],
    "capo": 0
  },
  {
      "chordName": "G5",
      "positions": [

      ],
      "capo": 0
  },
  {
      "chordName": "Gb",
      "positions": [
          { "string": 5, "fret": 2, "finger": 2 },
          { "string": 4, "fret": 2, "finger": 3 },
          { "string": 3, "fret": 1, "finger": 1 }
      ],
      "capo": 2
  },
  {
      "chordName": "Gmaj7",
      "positions": [
          { "string": 1, "fret": 2, "finger": 3 },
          { "string": 5, "fret": 2, "finger": 2 },

      ],
      "capo": 0
  },
  {
      "chordName": "Gm7",
      "positions": [
          { "string": 5, "fret": 3, "finger": 3 }
      ],
      "capo": 3
  },


  
  {
    "chordName": "G#",
    "positions": [
      { "string": 5, "fret": 2, "finger": 3 },
      { "string": 4, "fret": 2, "finger": 4 },
      { "string": 3, "fret": 1, "finger": 2 }
  ],
  "capo": 4
},

  {
      "chordName": "G#m",
      "positions": [
          { "string": 5, "fret": 2, "finger": 3 },
          { "string": 4, "fret": 2, "finger": 4 }
      ],
      "capo": 4
  },
  {
      "chordName": "G#7",
      "positions": [
        { "string": 5, "fret": 2, "finger": 2 },
        { "string": 3, "fret": 1, "finger": 3 }
    ],
    "capo": 1
  },
  {
      "chordName": "G#5",
      "positions": [
        { "string": 4, "fret": 1, "finger": 2 },
        { "string": 3, "fret": 1, "finger": 3 }

      ],
      "capo": 0
  },
  {
      "chordName": "G#b",
      "positions": [
        { "string": 6, "fret": 3, "finger": 2 },
        { "string": 5, "fret": 2, "finger": 3 },
        { "string": 1, "fret": 3, "finger": 1 }
    ],
    "capo": 0
  },
  {
      "chordName": "G#maj7",
      "positions": [
          { "string": 2, "fret": 1, "finger": 1 },
          { "string": 3, "fret": 1, "finger": 1 },
          { "string": 4, "fret": 1, "finger": 1 },
          { "string": 1, "fret": 3, "finger": 4 },
          { "string": 5, "fret": 3, "finger": 3 },

      ],
      "capo": 0
  },
  {
      "chordName": "G#m7",
      "positions": [
          { "string": 5, "fret": 2, "finger": 3 }
      ],
      "capo": 4
  },
];


const filteredChords = chordsData.filter(chord => transposedChords.includes(chord.chordName));



    return(
        <div>
        {
            filteredChords.map((chord, index) => (
                <ChordDiagram
                key={index}
                chordName={chord.chordName}
                positions={chord.positions}
                capo={chord.capo}
                />
        ))
        }
        </div>
    )

}

export default ChordDiagramTable;
