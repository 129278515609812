import React, { useState, useContext } from 'react';
import styles from './CommentForm.module.css';
import { AuthContext } from '../../contexts/AuthContext';
import { useNavigate,useParams } from 'react-router-dom';



const CommentForm = ({ onAddComment }) => {

  const { unique_song_id } = useParams();
  const [comment, setComment] = useState('');
  const { isAuthenticated, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSubmit = (e) => {

    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    e.preventDefault();
    // Example data structure, adjust according to your backend requirements
    const newCommentData = { 
      user_name: user.username, // Replace with actual user data
      unique_song_id:unique_song_id,
      comment: comment
    };
    onAddComment(newCommentData);
    setComment('');
  };
  return (
    <form className={styles.commentForm} onSubmit={handleSubmit}>
      <textarea
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        className={styles.textarea}
        placeholder="Bir yorum da sen ekle.."
      />
      <div className={styles.buttonContainer}>
        <button type="submit" className={styles.button}>Gönder</button>
      </div>
    </form>
  );
};

export default CommentForm;
