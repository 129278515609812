import axios from 'axios';

// Log function to capture and display information
const logRequest = (request) => {
    //console.log(`Request made to ${request.url} with method ${request.method} and data:`, request.data);
    return request;
};

const logResponse = (response) => {
    //console.log(`Received response from ${response.config.url}:`, response);
    return response;
};

const logError = (error) => {
    //console.error(`Error in request to ${error.config?.url}:`, error.response || error.message);
    return Promise.reject(error);
};

const apiBaseUrl = process.env.REACT_APP_API_URL; // Ensure fallback URL is correct
//console.log(apiBaseUrl);

// For authenticated requests
const authenticatedAxios = axios.create({
    baseURL: apiBaseUrl,
    withCredentials: true
});

authenticatedAxios.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['Authorization'] = `Token ${token}`;
    }
    return logRequest(config); // Log the request details
}, logError);

authenticatedAxios.interceptors.response.use(logResponse, logError);

// For non-authenticated requests
const nonAuthenticatedAxios = axios.create({
    baseURL: apiBaseUrl,
    withCredentials: true
});

nonAuthenticatedAxios.interceptors.request.use(logRequest, logError);
nonAuthenticatedAxios.interceptors.response.use(logResponse, logError);

export { authenticatedAxios, nonAuthenticatedAxios };