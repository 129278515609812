import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import TopSongs from '../components/TopSongs';
import styles from './MyListPage.module.css';
import { Helmet } from 'react-helmet';

const MyListPage = () => {
  const { genre } = useParams(); // This gets the genre from the URL in hyphenated lowercase format
  const navigate = useNavigate();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 768px)").matches);

  const genres = [
    "Indie", "2000ler Rock", "Yükselen Yetenekler", "Arabesk",
    "2000ler Pop", "Modern Halk Rock", "Güncel Pop", "Alternatif",
    "Klasik Pop İkonları", "80ler & 90lar Pop", "Milenyum Pop",
    "Gen Z", "Anadolu Rock", "Türk Halk Müziği"
  ];

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    const handleMediaQueryChange = (e) => setIsMobile(e.matches);
    mediaQuery.addListener(handleMediaQueryChange);

    return () => mediaQuery.removeListener(handleMediaQueryChange);
  }, []);

  useEffect(() => {
    // Redirect to default genre if none is selected
    if (!genre && genres.length > 0) {
      navigate(`/akor-calma-listeleri/${formatGenreForURL(genres[0])}`);
    }
  }, [genre, genres, navigate]);

  // Helper function to format genre names into URL-friendly strings
  const formatGenreForURL = (genreName) => {
    return genreName.toLowerCase().replace(/\s+/g, '-');
  };

  const formatGenreFromURL = (urlGenre) => {
    return urlGenre.replace(/-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  };

  
  const formattedGenre = genre ? genre.replace(/-/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : 'Çeşitli Türler';
  const title = genre ? `${formattedGenre} En Popüler Şarkıları` : "Popüler Türkçe Akorlar";
  const description = `Akor keşfetmeye ${formattedGenre} türleri ile başlayın ve çalmaya devam edin. En sevilen şarkılar burada.`;


  const howManyRows = 30;
  return (
    <div className={styles.pageContainer}>
       <Helmet>
          <title>{title}</title>
          <link rel="canonical" href={`https://www.melodikakor.com/akor-calma-listeleri/${genre}`} />
          <meta name="description" content={description} />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={description} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content="/path_to_default_genre_image.jpg" />
          <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "MusicPlaylist",
              "name": "${title}",
              "description": "${description}",
              "url": window.location.href
            }
          `}
          </script>
      </Helmet>
      <nav aria-label="Çalma listesini seç">
        <h1 className={styles.visually_hidden}>Çalma listeleri</h1>
        {isMobile ? (
          <div className={styles.genreSelectorContainer}>
            <button className={styles.genreButton} 
                    onClick={() => setIsPopupOpen(!isPopupOpen)} 
                    aria-expanded={isPopupOpen}
                    aria-haspopup="true">
              <i className="fas fa-chevron-down" style={{ marginRight: '5px' }} aria-hidden="true"></i> 
              {genre ? formatGenreFromURL(genre) : 'Tür Seç'}
            </button>
            {isPopupOpen && (
              <ul className={styles.genreListPopup}>
                {genres.map(genreName => (
                  <li key={genreName} className={styles.genreNameLi}>
                    <button
                      className={genre === formatGenreForURL(genreName) ? styles.selectedGenreButton : styles.notSelectedGenreButton}
                      aria-label={genreName}
                        onClick={() => {
                        navigate(`/akor-calma-listeleri/${formatGenreForURL(genreName)}`);
                        setIsPopupOpen(!isPopupOpen)
                      }  
                    }
                    >
                      {genreName}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        ) : (
          <ul className={styles.genreList}>
            {genres.map(genreName => (
              <li key={genreName} className={styles.genreNameLi}>
                <button
                  onClick={() => navigate(`/akor-calma-listeleri/${formatGenreForURL(genreName)}`)}
                  className={genre === formatGenreForURL(genreName) ? styles.selectedGenreButton : styles.notSelectedGenreButton}
                  aria-label={genreName}>
                  {genreName}
                </button>
              </li>
            ))}
          </ul>
        )}
      </nav>
      <section aria-labelledby="top-songs-header" className={styles.topSongsContainer}>
        <h2 className={styles.visually_hidden} id="top-songs-header"> {genre ? formatGenreFromURL(genre) : 'Indie'} En Populer Sarkilari</h2>
        <TopSongs 
          category_type='genres' 
          subcategory={genre ? formatGenreFromURL(genre) : 'Indie'} 
          title={genre ? formatGenreFromURL(genre) : 'Indie'} 
          howManyRows={howManyRows} 
        />
      </section>
    </div>
  );
};

export default MyListPage;
