import React, { useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import styles from './Header.module.css';
import { AuthContext } from '../contexts/AuthContext';

function Header() {
    const { isAuthenticated, user, logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target) && isMenuOpen) {
                setIsMenuOpen(false);
            }
        }
    
        // Adding the event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup the event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isMenuOpen]); // Include isMenuOpen in the dependency array


    const handleMySongsClick = (event) => {
        navigate(isAuthenticated ? '/favori-akorlarim' : '/login');
        setIsMenuOpen(false);
    };
    
    const handleListClick = (event) => {
        navigate('/akor-calma-listeleri/alternatif');
        setIsMenuOpen(false);
    };
    
    const handleLogout = (event) => {
        logout();
        navigate('/');
        setIsMenuOpen(false);
    };

    return (
        <header className={styles.headerStyle}>
            <Link to="/" className={styles.logoStyle}>melodikakor</Link>
            <div className={styles.searchContainer}>
                <SearchBar />
            </div>
            <div className={styles.mobileMenuIcon} onClick={() => setIsMenuOpen(!isMenuOpen)}>
                <i className="fas fa-bars"></i>
            </div>
            <div  className={`${styles.linksContainer} ${isMenuOpen ? styles.show : ''}`}>
                <a onClick={handleListClick} className={styles.linkStyle}><i className="fas fa-list" style={{ marginRight: '3px' }}></i> Listeler</a>
                <a onClick={handleMySongsClick} className={styles.linkStyle}><i className="fas fa-music" style={{ marginRight: '3px' }}></i> Favorilerim</a>
                {isAuthenticated && user ? (
                    <a onClick={handleLogout} className={styles.linkStyle}><i className="fas fa-sign-out-alt" style={{ marginRight: '3px' }}></i> Çıkış</a>
                ) : (
                    <Link to="/login" className={styles.linkStyle}><i className="fas fa-user" style={{ marginRight: '3px' }}></i> Giriş Yap</Link>
                )}
            </div>
            {isMenuOpen && (
                <div ref={menuRef} onClick={(event) => event.stopPropagation()} className={`${styles.mobileLinksContainer} ${isMenuOpen ? styles.show : ''}`}>
                    <a onClick={handleListClick} className={styles.linkStyle}><i className="fas fa-list" style={{ marginRight: '3px' }}></i> Listeler</a>
                    <a onClick={handleMySongsClick} className={styles.linkStyle}><i className="fas fa-music" style={{ marginRight: '3px' }}></i> Favorilerim</a>
                    {isAuthenticated && user ? (
                    <a onClick={handleLogout} className={styles.linkStyle}><i className="fas fa-sign-out-alt" style={{ marginRight: '3px' }}></i> Çıkış</a>
                    ) : (
                    <Link to="/login" className={styles.linkStyle}><i className="fas fa-user" style={{ marginRight: '3px' }}></i> Giriş Yap</Link>
                    )}
              </div>
            )}
        </header>
    );
}

export default Header;





