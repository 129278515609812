import React, { useState } from 'react';
import CommentList from './CommentList';
import CommentForm from './CommentForm';
import styles from './CommentsSection.module.css';
import { authenticatedAxios } from '../../utils/axiosConfig';


const CommentsSection = ({ comments: initialComments }) => {
  
  const [comments, setComments] = useState(initialComments || []);

  const addComment = async (newCommentData) => {
    try {
        console.log('Posting comment with data:', newCommentData);

        const response = await authenticatedAxios.post('/api/comment/', newCommentData);
        if (response.status === 200 || response.status === 201) { // Assuming 200 OK or 201 Created responses
            setComments(prevComments => [...prevComments, response.data]);
        } else {
            console.error('Failed to post comment:', response.data);
        }
    } catch (error) {
        console.error('There was an error posting the comment:', error);
    }
};

  return (
    <div className={styles.commentsSection}>
      <h2>Müzisyen Yorumları</h2>
      <CommentForm onAddComment={addComment}/>
      <CommentList comments={comments} />
      
    </div>
  );
};

export default CommentsSection;
