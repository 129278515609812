import { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { authenticatedAxios, nonAuthenticatedAxios } from '../utils/axiosConfig';
import { AuthContext } from '../contexts/AuthContext';

const usePageTrack = () => {
    const location = useLocation();
    const [startTime, setStartTime] = useState(Date.now());
    const authContext = useContext(AuthContext);
    const isAuthenticated = authContext?.isAuthenticated;
    const user = authContext?.user;

    // Function to call the tracking endpoint
    const trackVisit = async (path, duration, userId = null) => {
        const visitorId = Cookies.get('visitorId');
        const consentGiven = Cookies.get('userConsent') === 'true'; // Check user consent

        if (!consentGiven) {
            //console.log('User has not given consent, skipping tracking.');
            return;
        }

        const axiosInstance = userId ? authenticatedAxios : nonAuthenticatedAxios;

        try {
            await axiosInstance.post('/api/track-page-visit/', {
                userId,
                visitorId,
                path,
                duration,
            });
        } catch (error) {
            console.error('Error tracking page visit:', error);
        }
    };

    // Effect to track visits initially and on changes
    useEffect(() => {
        const duration = Date.now() - startTime;
        trackVisit(location.pathname, duration, user?.id);
    }, [isAuthenticated, user?.id, location.pathname]);

    // Effect to handle page unloads
    useEffect(() => {
        const handleUnload = () => {
            const duration = Date.now() - startTime;
            trackVisit(location.pathname, duration, user?.id);
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
            handleUnload(); // Call for final tracking before unmount
        };
    }, [location.pathname, user?.id]);

    // Reset start time on path change
    useEffect(() => {
        setStartTime(Date.now());
    }, [location.pathname]);
};

export default usePageTrack;
